import * as app from '../package.json';

const config = {
    app: {
        name: app.displayName
    },
    api: {
        endpoint: 'https://api.preprod.bolhero.com/api'
    }
}

export default config;